import React, { useState, createContext, useContext, useEffect, useCallback } from 'react';

import ReactGA from "react-ga";

import { useLocation } from 'react-router-dom';

const AnalyticsContext = createContext(null);

export { AnalyticsContext };

export const useAnalytics = () => useContext(AnalyticsContext);

export default ({ app, user, children }) => {

  const [loading, setLoading] = useState(true);
  const [pathname, setPathname] = useState("");

  let location = useLocation();

  useEffect(() => {
    ReactGA.initialize('UA-171717775-2');
    setLoading(false);
  }, []);

  useEffect(() => {
    if (user) {
      ReactGA.set({ user });
    }
  }, [user]);

  useEffect(() => {
    if (location.pathname != pathname) {
      setPathname(location.pathname);
      ReactGA.pageview(location.pathname);
    }


  }, [location.pathname]);

  return (
    <AnalyticsContext.Provider value={{  }}>
      {loading ? null : children}
    </AnalyticsContext.Provider>
  );
};