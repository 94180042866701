import React, { useEffect, useState, useContext, Fragment } from "react";

import TopBar from "../../components/TopBar";

import { useUserbase } from '../../Userbase';

import { Link } from "react-router-dom";

// WRAPPERS AND CONTEXT CREATORS
import { useNavigate } from "react-router-dom";

import ReactMarkdown from "react-markdown";
const htmlParser = require('react-markdown/plugins/html-parser')
var HtmlToReactParser = require("html-to-react").Parser;


export default ({app, ...props}) => {
  // FEATHERS CONTEXT LOAD
  const userbase = useUserbase();
  const navigate = useNavigate();

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [terms, setTerms] = useState(false);
  const [alert, setAlert] = useState({});

  const hexToRgb = (hex) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  };

  useEffect(() => {
    if (userbase.user) window.location = `/${app.slug}/`;
    document.documentElement.style.setProperty(
      "--theme-primary-color",
      app.color
    );
    const components = hexToRgb(app.color);
    if (components) {
      document.documentElement.style.setProperty(
        "--theme-primary-color-light",
        `rgba(${components.r},${components.g},${components.b},0.1)`
      );
      document.documentElement.style.setProperty(
        "--theme-primary-color-hover",
        `rgba(${components.r},${components.g},${components.b},0.85)`
      );
    }
  }, [app.slug]);

  const showConditions = e => {
    e.preventDefault();
    setAlert({
      visible: true,
      modal: true,
      html: app.terms||"Terms",
      okText: "Cerrar"
    })

  }


  const handleSubmit = (e) => {
    e.preventDefault();
    if (terms) {
      setSubmitting(true);
      userbase.signIn(email, password)
        .then(() => navigate(`/${app.slug}/`))
        .catch((e) => {
          setSubmitting(false);
          console.log("Auth error", e);
          setAlert({
            visible: true,
            modal: true,
            title: app.platformCopies.loginValidationTitle,
            body: app.platformCopies.loginValidationBody,
            okText: app.platformCopies.loginValidationButton
          });
        });
    } else {
      setAlert({
        visible: true,
        modal: true,
        title: app.platformCopies.loginTermsAcceptanceTitle,
        body: app.platformCopies.loginTermsAcceptanceBody,
        okText: app.platformCopies.loginTermsAcceptanceButton
      });
    }
  };

  if (app.loginSubtitle && app.loginSubtitle != "") {
    var htmlToReactParser = new HtmlToReactParser();
    var subtitleElement = htmlToReactParser.parse(app.loginSubtitle);
  }

  const parse = htmlParser({
    isValidNode: (node) => node.type !== 'script',
    processingInstructions: [/* ... */]
  })

  return (
    <React.Fragment>
      {
        app.loginable && (
          <div
            style={{ minHeight: "100vh", display: "flex", flexDirection: "column", backgroundColor: app.color }}
          >
            <TopBar app={app} />
            <div className="empty-container" style={{  }}>
              <div className="login">
                <div
                  className="logo"
                  style={{
                    backgroundImage: `url(${app.logo})`,
                  }}
                ></div>
                <h2>
                  {app.platformCopies.loginTitle}
                </h2>
                <h3>{app.title}</h3>
                <form style={{ display: 'flex', flexDirection: 'column' }}>
                  <div className="pill login-form">
                    <input
                      autofocus
                      type="text"
                      placeholder={app.platformCopies.loginEmailField}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <input
                      type="password"
                      placeholder={app.platformCopies.loginPasswordField}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  {error && !submitting && (
                    <div className="message-box show">{error}</div>
                  )}
                  <label className="help">
                    <input
                      type="checkbox"
                      onChange={(e) => setTerms(e.target.checked)}
                    />{" "}
                    {app.platformCopies.loginAcceptance}{" "}
                    <a href="#" onClick={showConditions}>
                      {app.platformCopies.loginAcceptanceButton}
                    </a>
                  </label>
                  {subtitleElement ?
                    <label style={{ maxWidth: 320, alignSelf: 'center' }} className="help">{subtitleElement}</label>
                  : null}

                  <button
                    disabled={submitting}
                    onClick={handleSubmit}
                    className="btn btn-block btn-lg"
                  >
                    {submitting
                      ? app.platformCopies.loginSubmittingButton
                      : app.platformCopies.loginSubmitButton}
                  </button>
                  {app.registrable && (
                    <p className="help">
                      {app.platformCopies.loginRegisterText}{" "}
                      <Link to={`/${app.slug}/signup`}>
                        {app.platformCopies.loginRegisterButton}
                      </Link>
                    </p>
                  )}
                </form>
              </div>
            </div>
          </div>
        )
      }
      <div className={`AlertOverlay ${alert.visible ? '' : 'hidden'}`} onClick={() => setAlert({})}></div>
      {alert && alert.visible ?
        <div className={alert.modal ? 'Modal' : 'Alert'}>
          {alert.html ?
            <ReactMarkdown children={alert.html} htmlParser={parse} skipHtml={false} allowDangerousHtml />
            :
            <Fragment>
              <h2 className="Title">{alert.title}</h2>
              {alert.bodyElement ?
                <div className="BodyElement">{alert.bodyElement}</div>
              :
                <p className="Body">{alert.body}</p>
              }

            </Fragment>
          }

          <div className={`Actions ${!alert.cancelText && 'one'}`}>
            {alert.cancelText &&
              <button className="btn btn-outline" type="button" onClick={() => setAlert({})}>{alert.cancelText}</button>
        }

            <button className="btn" type="button" onClick={() => setAlert({})}>{alert.okText}</button>
        </div>
      </div>
      : null}
    </React.Fragment>
  );
}
