import React, { Fragment, useState, useEffect } from "react";

import { Link } from "react-router-dom";

import $ from "jquery";


export const foldUnfold = (e, what = false) => {
  e.preventDefault();
  if ($(".curtain").hasClass("folded")) {
    $(".Central, .curtain, .sidebar").removeClass("folded");
  } else {
    if (!what || $(`.inner.${what}`).is(":visible")) {
      $(".Central, .curtain, .sidebar").addClass("folded");
    }
  }

  if (what) {
    $(".inner").hide();
    $(`.inner.${what}`).show();
  }
};

export default (props) => {

  return (
    <Fragment>
      <div class="curtainOverlay folded" onClick={(e) => foldUnfold(e)}></div>
      <div className="curtain folded">
        {props.app.hasAgenda &&
          <div class="inner agenda">
            <div className="top">
              <h3>
                Agenda
              </h3>
              <a href="#" onClick={(e) => foldUnfold(e)}>
                <span class="icon-md-arrow-left"></span>
              </a>
            </div>
            <div className="day">
              <div className="hour">
                <div className="appointments">
                  {props.app.agendaItems.map( (ai,k) => (
                    <div className="appointment">
                      <div className="firstCol">
                        <h4>{ai.title}</h4>
                        <span
                          className={`time `}
                        >
                          {ai.subtitle}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

          </div>
        }

      </div>
    </Fragment>
  );
};
