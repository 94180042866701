import React, { } from "react";

import { useNavigate } from "react-router-dom";
import { useUserbase } from '../../Userbase';

// WRAPPERS AND CONTEXT CREATORS
import { Link } from "react-router-dom";



const NavLink = (props) => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        className: isCurrent ? "active" : "",
      };
    }}
  />
);

export default (props) => {

  const { user, signOut } = useUserbase();

  return (
    <div className="top-bar">
      <div className="left">
        {props.icon && props.icon != '' &&
          <div className="logo" style={{ background: `url(${props.icon}) center center no-repeat`, backgroundSize: 'cover' }}></div>
        }
        <h1>{props.title}</h1>
      </div>
      {user ? (
        <div className="right">
          <span class="basic-thumb" onClick={(e) => {
            e.preventDefault();
          }}>
            {user.profile.firstName.substr(0, 1)}
            {user.profile.lastName.substr(0, 1)}
          </span>
          <a href="#" onClick={(e) => {
            e.preventDefault();
            signOut();
          }}>
            {props.app.platformCopies.logOutButton}
          </a>
        </div>
      ) : (
        <div className="right">
          {props.app.loginable && (
            <Link to={`/${props.app.slug}/signin`}>
              {props.app.platformCopies.loginButton}
            </Link>
          )}
          {props.app.registrable && (
            <Link to={`/${props.app.slug}/signup`}>
              {props.app.platformCopies.signUpButton}
            </Link>
          )}
        </div>
      )}
    </div>
  );

};
