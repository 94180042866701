import React, { useEffect } from 'react';

import StaticModule from './components/StaticModule';
import TypeformModule from './components/TypeformModule';
import SlidoModule from './components/SlidoModule';

var HtmlToReactParser = require("html-to-react").Parser;

export default props => {

  useEffect(() => {
    (function() { var qs,js,q,s,d=document, gi=d.getElementById, ce=d.createElement, gt=d.getElementsByTagName, id="typef_orm", b="https://embed.typeform.com/"; if(!gi.call(d,id)) { js=ce.call(d,"script"); js.id=id; js.src=b+"embed.js"; q=gt.call(d,"script")[0]; q.parentNode.insertBefore(js,q) } })()
  }, []);

  if (props.app && props.app.description) {
    var htmlToReactParser = new HtmlToReactParser();
    var descriptionElement = htmlToReactParser.parse(props.app.description);
  }

  return (
    <div className="sidebar folded">
      <div
        className="logo"
        style={{
          backgroundColor: props.app.color,
          backgroundImage: `url(${props.app.logo})`,
        }}
      ></div>
      <div className="sidebar-content">
        {props.app.modules.map( (m,k) => {
          switch (m.template) {
            case 'modulo-estatico':
              return <StaticModule module={m} />
            break;
            case 'modulo-typeform':
              return <TypeformModule module={m} />
            break;
            case 'modulo-sli-do':
              return <SlidoModule module={m} />
            break;
          }
        })}
      </div>
    </div>
  );
}