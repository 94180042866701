import React, { useEffect, useState, useContext, Fragment } from "react";

import TopBar from "../../components/TopBar";

import { Link } from "react-router-dom";

// WRAPPERS AND CONTEXT CREATORS
import { useNavigate } from "react-router-dom";
import { useUserbase } from "../../Userbase";

import ReactMarkdown from "react-markdown";
const htmlParser = require('react-markdown/plugins/html-parser')

export default ({app, ...props}) => {

  const [alert, setAlert] = useState({});

  // FEATHERS CONTEXT LOAD
  const userbase = useUserbase();;
  const navigate = useNavigate();

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [terms, setTerms] = useState(false);

  const hexToRgb = (hex) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  };

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--theme-primary-color",
      app.color
    );
    const components = hexToRgb(app.color);
    if (components) {
      document.documentElement.style.setProperty(
        "--theme-primary-color-light",
        `rgba(${components.r},${components.g},${components.b},0.1)`
      );
      document.documentElement.style.setProperty(
        "--theme-primary-color-hover",
        `rgba(${components.r},${components.g},${components.b},0.85)`
      );
    }
  }, [props.manifest]);

  const showConditions = e => {
    e.preventDefault();
    setAlert({
      visible: true,
      modal: true,
      html: app.terms||"Terms",
      okText: "Cerrar"
    })

  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (terms) {
      setSubmitting(true);
      userbase.signUp(email, password, { firstName, lastName })
        .then(() => navigate(`/${app.slug}/`))
        .catch((e) => {
          setSubmitting(false);
          console.log("Register error", e);
        });
    } else {
      setAlert({
        visible: true,
        modal: true,
        title: app.platformCopies.signUpTermsAcceptanceTitle,
        body: app.platformCopies.signUpTermsAcceptanceBody,
        okText: app.platformCopies.signUpTermsAcceptanceButton
      });
    }

  };

  const parse = htmlParser({
    isValidNode: (node) => node.type !== 'script',
    processingInstructions: [/* ... */]
  })


  return (
    <React.Fragment>
      {
        app.loginable && (
          <div
            style={{ minHeight: "100vh", display: "flex", flexDirection: "column", backgroundColor: app.color }}
          >
            <TopBar app={app} />
            <div className="empty-container" style={{  }}>
              <div className="login">
                <div
                  className="logo"
                  style={{
                    backgroundImage: `url(${app.logo})`,
                  }}
                ></div>
                <h2>
                  {app.platformCopies.signUpTitle}
                </h2>
                <h3>{app.title}</h3>
                <form>
                  <div className="pill login-form">
                    <input
                      autofocus
                      type="text"
                      placeholder={app.platformCopies.signUpEmailField}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <input
                      type="text"
                      placeholder={app.platformCopies.signUpFirstNameField}
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                    <input
                      type="text"
                      placeholder={app.platformCopies.signUpLastNameField}
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                    <input
                      type="password"
                      placeholder={app.platformCopies.signUpPasswordField}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <label className="help">
                    <input
                      type="checkbox"
                      onChange={(e) => setTerms(e.target.checked)}
                    />{" "}
                    {app.platformCopies.signUpAcceptance}{" "}
                    <a href="#" onClick={showConditions}>
                      {app.platformCopies.signUpAcceptanceButton}
                    </a>
                  </label>
                  {error && !submitting && (
                    <div className="message-box show">{error}</div>
                  )}

                  <button
                    disabled={submitting}
                    onClick={handleSubmit}
                    className="btn btn-block btn-lg"
                  >
                    {submitting
                      ? app.platformCopies.signUpSubmittingButton
                      : app.platformCopies.signUpSubmitButton }
                  </button>
                  {app.loginable && (
                    <p className="help">
                      {app.platformCopies.signUpLoginText}{" "}
                      <Link to={`/${app.slug}/signin`}>
                        {app.platformCopies.signUpLoginButton}
                      </Link>
                    </p>
                  )}
                </form>
              </div>
            </div>
          </div>
        )
      }
      <div className={`AlertOverlay ${alert.visible ? '' : 'hidden'}`} onClick={() => setAlert({})}></div>
      {alert && alert.visible ?
        <div className={alert.modal ? 'Modal' : 'Alert'}>
          {alert.html ?
            <ReactMarkdown children={alert.html} htmlParser={parse} skipHtml={false} allowDangerousHtml />
            :
            <Fragment>
              <h2 className="Title">{alert.title}</h2>
              {alert.bodyElement ?
                <div className="BodyElement">{alert.bodyElement}</div>
              :
                <p className="Body">{alert.body}</p>
              }

            </Fragment>
          }

          <div className={`Actions ${!alert.cancelText && 'one'}`}>
            {alert.cancelText &&
              <button className="btn btn-outline" type="button" onClick={() => setAlert({})}>{alert.cancelText}</button>
        }

            <button className="btn" type="button" onClick={() => setAlert({})}>{alert.okText}</button>
        </div>
      </div>
      : null}
    </React.Fragment>
  );
}
