import React, {} from 'react';

import { useUserbase } from '../../../../Userbase';

var HtmlToReactParser = require("html-to-react").Parser;

export default props => {

  const { user } = useUserbase();

  if (props.module && props.module.content) {
    var htmlToReactParser = new HtmlToReactParser();
    var descriptionElement = htmlToReactParser.parse(props.module.content);
  }

  const userName = `${user.profile.firstName} ${user.profile.lastName}`;

  return (
    <div class="marginated">
      <div class="title">
        <span className={`icon-md-${props.module.icon}`}></span>
        <h3>
          {props.module.title}
        </h3>
      </div>
      <div style={{ position: 'relative', overflow: 'hidden' }}>
        <iframe
          src={`https://app.sli.do/event/${props.module.code}/live/questions?lang=es_ES&user_name=${userName}&user_email=${user.email}&user_company=${user.email}`} height="100%" width="100%" frameborder="0" style={{ minHeight: 560, position: 'relative', top: -56, marginBottom: -56 }}></iframe>
      </div>
    </div>
  );
}