import React, { useState, createContext, useContext, useEffect, useCallback } from 'react';

import userbase from 'userbase-js';

const UserbaseContext = createContext(null);

export { UserbaseContext };

export const useUserbase = () => useContext(UserbaseContext);

export default ({ app, children }) => {

  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(false);

  useEffect(() => {
    setLoading(true);
    userbase.init({ appId: app.userbaseApp||'a126a7ad-0913-427b-8ace-58869b891fc6' })
      .then((session) => setUser(session.user||false))
      .catch(() => setUser(false))
      .finally(() => setLoading(false));
  }, [app.slug]);

  const signIn = useCallback((username, password) => userbase.signIn({
      username,
      password,
      rememberMe: 'local'
    }).then((user) => {
      setUser(user);
      // user logged in
    }), [app.slug]);

  const signUp = useCallback((username, password, profile) => userbase.signUp({
      username,
      email: username,
      password,
      profile,
      rememberMe: 'local'
    }).then((user) => {
      setUser(user);
      // user logged in
    }), [app.slug]);

  const signOut = useCallback(() => userbase.signOut().then(r => setUser(false)), [app.slug]);

  return (
    <UserbaseContext.Provider value={{ user, signUp, signIn, signOut }}>
      {loading ? null : children}
    </UserbaseContext.Provider>
  );
};