import React, { useEffect } from 'react';

import { BrowserRouter, Routes, Route, Link, Navigate } from "react-router-dom";

import UserbaseProvider, { UserbaseContext } from './Userbase';

import AnalyticsProvider from './Analytics';

// Scenes
import Splash from './scenes/Splash';
import Room from './scenes/Room';
import SignIn from './scenes/SignIn';
import SignUp from './scenes/SignUp';

import './App.scss';

import appsConfig from './apps.json';

const hexToRgb = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

function App() {

  const slug = window.location.pathname.split("/").filter((p) => p !== "")[0];
  if (!slug) window.location = '/event/';
  const app = appsConfig.apps.filter(a => a.slug == slug)[0];

  useEffect(() => {
    if (app && app.color) {
      document.documentElement.style.setProperty(
        "--theme-primary-color",
        app.color
      );
      const components = hexToRgb(app.color);
      if (components) {
        document.documentElement.style.setProperty(
          "--theme-primary-color-light",
          `rgba(${components.r},${components.g},${components.b},0.1)`
        );
        document.documentElement.style.setProperty(
          "--theme-primary-color-hover",
          `rgba(${components.r},${components.g},${components.b},0.85)`
        );
      }
    }

  }, [app]);

  return app ?
      (
        <UserbaseProvider app={app}>
          <UserbaseContext.Consumer>
            {userbaseContext => (

                <BrowserRouter>
                  <AnalyticsProvider app={app} user={userbaseContext.user}>
                    {userbaseContext.user || !app.private ?
                      <Routes basename={app.slug}>
                        <Route path="/" element={<Room app={app} />} />
                        <Route path="/signin" element={<SignIn app={app} />} />
                        <Route path="/signup" element={<SignUp app={app} />} />
                      </Routes>
                      :
                      <Routes basename={app.slug}>
                        <Route path="/signin" element={<SignIn app={app} />} />
                        <Route path="/signup" element={<SignUp app={app} />} />
                        <Route path="*" element={<SignIn app={app} />} />
                      </Routes>
                    }
                  </AnalyticsProvider>

                </BrowserRouter>
            )}
          </UserbaseContext.Consumer>
        </UserbaseProvider>
      )
    :
      <Splash />;
}

export default App;
