import React from "react";

// OWN ROOT COMPONENTS
import { foldUnfold } from "../Curtain";


// OTHER UTILS
import { NavLink } from "react-router-dom";

// STYLING
const styles = {};

export default (props) => {
  return props.app.hasAgenda ? (
    <div className="leftBar">
      <NavLink className="item" to={`/${props.app.slug}`} end>
        <span class="icon-md-home"></span> Sala
      </NavLink>
      <a href="#" class="item" onClick={(e) => foldUnfold(e, "agenda")}>
        <span class="icon-md-calendar"></span> Agenda
      </a>
    </div>
  ) : null;
};
