import React, { useEffect } from 'react';

import TopBar from '../../components/TopBar';
import LeftBar from '../../components/LeftBar';
import Curtain from '../../components/Curtain';
import SideBar from '../../components/SideBar';

import { useUserbase } from '../../Userbase';

var HtmlToReactParser = require("html-to-react").Parser;

export default (props) => {

  const userbase = useUserbase();

  if (props.app && props.app.description) {
    var htmlToReactParser = new HtmlToReactParser();
    var descriptionElement = htmlToReactParser.parse(props.app.description);
  }

  return (
    <React.Fragment>
      <TopBar app={props.app} icon={props.app.icon} title={props.app.title} />
      <div className="Outmost">
        <LeftBar app={props.app} />
        <div className="Outer">
          <div className="Central folded" style={props.app.modules.length == 0 ? {maxWidth: "70%", margin: "0 auto"} : {}}>
            {props.app.iframe && props.app.iframe != '' ?
              <div className="embed-outer">
                <div className="embed llucana">
                  <iframe
                    title="External"
                    src={props.app.iframe}
                    scrolling="no"
                    frameborder="0"
                    allow="autoplay; fullscreen"
                    allowFullScreen
                    webkitallowfullscreen
                    mozallowfullscreen
                    // style="margin-top:-72px;height:800px;"
                  ></iframe>
                </div>
              </div>
            : null }
            <div className="Content">
              {descriptionElement}
            </div>

          </div>
          {props.app.modules.length != 0 &&
            <SideBar app={props.app} />
          }
          <Curtain app={props.app} />
        </div>
      </div>
    </React.Fragment>
  );
}