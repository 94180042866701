import React, {} from 'react';

var HtmlToReactParser = require("html-to-react").Parser;

export default props => {

  if (props.module && props.module.content) {
    var htmlToReactParser = new HtmlToReactParser();
    var descriptionElement = htmlToReactParser.parse(props.module.content);
  }

  return (
    <div class="marginated">
      <div class="title">
        <span className={`icon-md-${props.module.icon}`}></span>
        <h3>
          {props.module.title}
        </h3>
      </div>
      {descriptionElement}
    </div>
  );
}