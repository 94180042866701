import React, {} from 'react';

import { useUserbase } from '../../../../Userbase';

var HtmlToReactParser = require("html-to-react").Parser;

export default props => {

  const { user } = useUserbase();

  if (props.module && props.module.content) {
    var htmlToReactParser = new HtmlToReactParser();
    var descriptionElement = htmlToReactParser.parse(props.module.content);
  }

  const userName = `${user.profile.firstName} ${user.profile.lastName}`;

  return (
    <div class="marginated">
      <div class="title">
        <span className={`icon-md-${props.module.icon}`}></span>
        <h3>
          {props.module.title}
        </h3>
      </div>
      <div class="typeform-widget" data-url={`https://form.typeform.com/to/${props.module.code}?typeform-medium=embed-snippet#nombre=${userName}&mail=${user.email}`} style={{ width: '100%', height: props.module.embedHeight}}></div>
    </div>
  );
}